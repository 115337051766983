// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 768px) {
  h1, h2 {
    font-size: 1.8em;
  }

  .services-card,
  .testimonial-card {
    width: 90%;
    margin: 10px auto;
  }

  nav a {
    display: block;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  h1, h2 {
    font-size: 1.5em;
  }

  button {
    padding: 10px;
    font-size: 0.9em;
  }
}


html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

* {
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;;IAEE,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,cAAc;EAChB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;AACF;;;AAGA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iCAAiC;EACjC,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');\n\nbody {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Roboto', sans-serif;\n}\n\n@media (max-width: 768px) {\n  h1, h2 {\n    font-size: 1.8em;\n  }\n\n  .services-card,\n  .testimonial-card {\n    width: 90%;\n    margin: 10px auto;\n  }\n\n  nav a {\n    display: block;\n    margin: 10px 0;\n  }\n}\n\n@media (max-width: 480px) {\n  h1, h2 {\n    font-size: 1.5em;\n  }\n\n  button {\n    padding: 10px;\n    font-size: 0.9em;\n  }\n}\n\n\nhtml, body, #root {\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  font-family: 'Roboto', sans-serif;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n* {\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
